import { db, fb, auth, storage } from '../../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { fetchStudents, fetchTeachers } from '../reducers/student.slice';
import firebase from "firebase/app";

export const uploadDocImages = (file) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file.name.split('.').pop();
  console.log('Upload Starting...: ', imageName);

  const uploadTask = storage.ref(`student_docs/${imageName}`).put(file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // setProgress(progress);
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      () => {
        storage
          .ref('student_docs')
          .child(imageName)
          .getDownloadURL()
          .then((url) => {
            console.log('Image URL: ', url);
            resolve(url);
          })
          .catch((error) => reject(error));
      }
    );
  });
};

export const createStudentResult = async (data, navigate, setLoading) => {
  try {
    //setLoading(true);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();

    console.log("DATA:::", data);

    /*const resultRef =*/  db.collection('result').add({
        studentId: data.studentId,
        teacherId: data.teacherId,
        totalCumulative: data.totalCumulative,
        finalGrade: data.finalGrade,
        dateCreated: today.toLocaleDateString('en-US', options),
        subjects: data.scores,
      }).then(async(res)=>{ 

          db.collection('result').doc(res.id).update({
           resultId: res.id,
         });
  })

    console.log('Saved Successfully');
    notifySuccessFxn('Result Saved Successfully');
    navigate('/dashboard/home');
    //setLoading(true);
  } catch (error) {
    console.log('Error creating result:', error);
    notifyErrorFxn('Error creating result');
    //setLoading(false);
  }
};


export const createStudent = async (studentData, navigate, setLoading) => {
  try {
    setLoading(true);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();
    console.log('STUDENT_DATA:::', studentData);

    const studentRef = await db.collection('students').add({
      // registrationId: nanoid(8),
      fname: studentData?.state?.fname,
      lname: studentData?.state?.lname,
      dob: studentData?.state?.dob,
      gender: studentData?.state?.gender,
      studentshipType: studentData?.state?.studentshipType,
      registrationId: studentData?.state?.registrationId,
      class: studentData?.state?.class,
      section:studentData.state.class && (
        studentData.state.class =="Niveau 1"||
        studentData.state.class =="Niveau 2"||
        studentData.state.class =="Niveau 3"||
        studentData.state.class =="Niveau 4"||
        studentData.state.class =="Niveau 5"||
        studentData.state.class =="Niveau 6" ) 
       ? "Elementary":"Secondary",
      guardianName: studentData?.state?.guardianName,
      bloodGroup: studentData?.state?.bloodGroup,
      religion: studentData?.state?.religion,
      phoneNumber: studentData?.state?.phoneNumber,
      email: studentData?.state?.email,
      skinColor: studentData?.state?.skinColor,
      eyeColor: studentData?.state?.eyeColor,
      height: studentData?.state?.height,
      nationality: studentData?.state?.nationality,
      admissionDate: studentData?.state?.admissionDate,
      admissionTerminated: studentData?.state?.admissionTerminated,
      medicalHistory: studentData?.state?.medicalHistory,
      specialInstruction: studentData?.state?.specialInstruction,
      studentPassportFileUrl: studentData.studentPassportFileUrl,
      anotherFieldFileUrl: studentData.anotherFieldFileUrl,
      mothersIdFileUrl: studentData.mothersIdFileUrl,
      schoolId:studentData.schoolId,
      certificateFileUrl: studentData.certificateFileUrl,
      medicalRecordFileUrl: studentData.medicalRecordFileUrl,
      accountCreated: today.toLocaleDateString('en-US', options),
    });

    console.log('RESPONSE ID: ', studentRef.id);

    await db.collection('students').doc(studentRef.id).update({
      studentId: studentRef.id,
    });


    await db.collection('parents')
    .where('email', '==', studentData.state.email) // Adjust the field name if needed
    .get()
    .then(async(snapshot)=>{   

    console.log("PARENT EXISTS INFO--->",snapshot.docs)

     if(snapshot.docs.length >0 ){


      await db.collection('parents').doc(snapshot.docs[0].data().id).update({
        students: firebase.firestore.FieldValue.arrayUnion(studentRef.id)
      });

     }else{


      if(studentData.state.email){  

        fb.auth().createUserWithEmailAndPassword(
          studentData.state.email,
          '123456'
      ).then(async(res)=>{
    
        await db.collection('parents').doc(res.user.uid).set({
          id:res.user.uid,
          parentId:res.user.uid,
          schoolId:studentData.schoolId,
          accountCreated: today.toLocaleDateString('en-US', options),
          parentIdFileUrl: studentData.mothersIdFileUrl,
          email: studentData?.state?.email,
          password:'123456',
          phoneNumber: studentData?.state?.phoneNumber,
          students:[
            studentRef.id
          ],
    
           })
           
    
    
         }
    
    
        )
    
    
      }else{
    
    
        await db.collection('parents').add({
         
          schoolId:studentData.schoolId,
          accountCreated: today.toLocaleDateString('en-US', options),
          parentIdFileUrl: studentData.mothersIdFileUrl,
          email: studentData?.state?.email,
          password:'123456',
          phoneNumber: studentData?.state?.phoneNumber,
          students:[
            studentRef.id
          ],
    
           })
           .then((res)=>{

            db.collection('parents')
          .doc(res.id)
          .update({
            id:res.user.id,
            parentId:res.user.id,
          })
      
           })
    
      }

   }

  
})






    console.log('Created Successfully');
    notifySuccessFxn('Student Profile Created Successfully');
    navigate('/dashboard/home');
    setLoading(true);
  } catch (error) {
    console.log('Error creating student:', error);
    notifyErrorFxn('Error creating student profile');
    setLoading(false);
  }
};

export const getStudents = (schoolId) => async (dispatch) => {
  try {
    const studentsSnapshot = await db.collection('students')
    .where('schoolId', '==', schoolId)
    .get();

    const students = studentsSnapshot.docs.map((studentDoc) => {
      const studentData = studentDoc.data();
      return { id: studentDoc.id, ...studentData };
    });

    const studentsWithResultsPromises = students.map(async (student) => {
      const resultsSnapshot = await db.collection('result')
        .where('studentId', '==', student.studentId) // Adjust the field name if needed
        .get();

      const results = resultsSnapshot.docs.map((resultDoc) => resultDoc.data());
      return { ...student, results };
    });

    const studentsWithResults = await Promise.all(studentsWithResultsPromises);

    console.log('STUDENTS WITH RESULTS:', studentsWithResults);
    dispatch(fetchStudents(studentsWithResults));
  } catch (error) {
    console.error('Error fetching students and results:', error);
  }
};

export const deleteStudent = (studentData, navigate,user) => async (dispatch) => {

  db.collection('students')
  .doc(studentData.studentId)
  .delete(
    dispatch(getStudents(user?.schoolId))
  )
  .then((res)=>{
   
  })
  .then((res) => {
    //setLoading(false);
    notifySuccessFxn('Student has been deleted!');
     navigate('/dashboard/reports', { replace: true });
  })
  .catch((err) => {
    //setLoading(false);
    console.log('ERR-: ', err);
  });

}


export const updateStudentAttendance = (studentId,attendanceArray) => async (dispatch) => {
  db.collection('students')
    .doc(studentId)
    .update({

    attendance:attendanceArray

    }).then((res) => {
     
     console.log("updated attendance array!")
     
    })
    .catch((err) => {
     
      console.log('ERR updating student data-: ', err);
    });
};




export const updateStudent = (studentData, navigate, setLoading) => async (dispatch) => {
  db.collection('students')
    .doc(studentData.studentId)
    .update({
      fname: studentData.fname,
      lname: studentData.lname,
      dob: studentData.dob,
      gender: studentData.gender,
      //studentshipType: studentData.studentshipType,
      registrationId: studentData.registrationId,
      class: studentData.class,
      section:studentData.class && (
        studentData.class =="Niveau 1"||
        studentData.class =="Niveau 2"||
        studentData.class =="Niveau 3"||
        studentData.class =="Niveau 4"||
        studentData.class =="Niveau 5"||
        studentData.class =="Niveau 6" ) 
       ? "Elementary":"Secondary",
      //guardianName: studentData.guardianName,
      //bloodGroup: studentData.bloodGroup,
      //religion: studentData.religion,
      phoneNumber: studentData.phoneNumber,
      email: studentData.email,
      //skinColor: studentData.skinColor,
      //eyeColor: studentData.eyeColor,
      //height: studentData.height,
      nationality: studentData.nationality,
      admissionDate: studentData.admissionDate,
      admissionTerminated: studentData.admissionTerminated,
      medicalHistory: studentData.medicalHistory,
      specialInstruction: studentData.specialInstruction,
      studentPassportFileUrl: studentData.studentPassportFile ?? "",
      anotherFieldFileUrl: studentData.anotherFieldFile ?? "",
      mothersIdFileUrl: studentData.mothersIdFile ?? "",
      certificateFileUrl: studentData.certificateFile ?? "",
      medicalRecordFileUrl: studentData.medicalRecordFile ?? "",
    })
    .then((res) => {
      setLoading(false);
      notifySuccessFxn('Updated successfully');
      // navigate('/dashboard/home', { replace: true });
    })
    .catch((err) => {
      setLoading(false);
      console.log('ERR-: ', err);
    });
};

export const createTeacher = async (teacherData, navigate, setLoading) => {
  try {
    setLoading(true);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();

    const teacherRef = await db.collection('teachers').add({
      fname: teacherData?.state?.fname,
      lname: teacherData?.state?.lname,
      email: teacherData?.state?.email,
      dob: teacherData?.state?.dob,
      gender: teacherData?.state?.gender,
      studentshipType: teacherData?.state?.studentshipType,
      registrationId: teacherData?.state?.registrationId,
      class: teacherData?.state?.class,
      section: teacherData?.state?.section,
      teacherPassportFileUrl: teacherData.teacherPassportFileUrl,
      accountCreated: today.toLocaleDateString('en-US', options),
    });

    await db.collection('teachers').doc(teacherRef.id).update({
      teacherId: teacherRef.id,
    });

    console.log('Created Successfully');
    notifySuccessFxn('Teacher Profile Created Successfully');
    navigate('/dashboard/home');
    setLoading(true);
  } catch (error) {
    console.log('Error creating student:', error);
    notifyErrorFxn('Error creating student profile');
    setLoading(false);
  }
};

export const getTeachers = () => async (dispatch) => {
  db.collection('teachers')
    .get()
    .then((snapshot) => {
      const teachers = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log("TEACHERSSS", teachers);
      dispatch(fetchTeachers(teachers));
    })
    .catch((error) => {
      var errorMessage = error.message;
      console.log('Error fetching teachers', errorMessage);
    });
};

export const updateTeacher = (teacherData, navigate, setLoading) => async (dispatch) => {
  console.log("TEACHHER_DAYA", teacherData);

  db.collection('teachers')
    .doc(teacherData?.state?.teacherId)
    .update({
      fname: teacherData?.state?.fname,
      lname: teacherData?.state?.lname,
      email: teacherData?.state?.email,
      dob: teacherData?.state?.dob,
      gender: teacherData?.state?.gender,
      studentshipType: teacherData?.state?.studentshipType,
      registrationId: teacherData?.state?.registrationId,
      class: teacherData?.state?.class,
      section: teacherData?.state?.section,
      // teacherPassportFileUrl: teacherData?.teacherPassportFileUrl ? teacherData.teacherPassportFileUrl : '',
    })
    .then((res) => {
      setLoading(false);
      notifySuccessFxn('Updated successfully');
      navigate('/dashboard/home', { replace: true });
    })
    .catch((err) => {
      setLoading(false);
      console.log('ERR-: ', err);
    });
};
